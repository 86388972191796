<div class="flex flex-col justify-center items-center py-8" *ngIf="isComponentLoaded">
  <!-- Quiz Header -->
  <div
    *ngIf="isQuizOpen"
    class="glass-effect max-w-3xl w-9/12 bg-gradient-to-r from-customDarkBlue to-customBlue rounded-lg p-6 mb-8"
  >
    <h1 class="text-3xl font-bold text-customVeryLightBlue text-center">
      {{ quiz.title }}
    </h1>
    <div class="text-2xl font-semibold text-white text-center mt-4">
      Time remaining: {{ formattedTime }}
    </div>
  </div>

  <!-- Quiz Body -->
  <div
    *ngIf="isQuizOpen"
    class="max-w-3xl w-full bg-gradient-to-r from-customDarkBlue to-customBlue rounded-lg p-8"
  >
    <div class="text-2xl font-semibold text-white mb-10">
      {{ currentQuestion.text }}
    </div>
    <div class="space-y-4">
      <!-- Answers -->
      <div
        *ngFor="let answer of currentQuestion.answers"
        class="glass-effect relative bg-customBlue bg-opacity-70 rounded-lg cursor-pointer transition duration-300 transform hover:scale-105 hover:bg-customDarkBlue hover:text-customVeryLightBlue overflow-hidden flex items-center"
        (click)="selectAnswer(answer)"
      >
        <span class="block p-4 text-xl text-center text-white flex-1">{{
          answer.text
        }}</span>
        <span
          *ngIf="answer.isSelected"
          class="absolute inset-y-0 right-0 flex items-center pr-4"
        >
          <span class="bg-white rounded-full p-2">
            <svg
              class="w-6 h-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </span>
      </div>
    </div>
    <div class="flex justify-evenly items-center">
      <button
        *ngIf="this.currentQuestionIndex > 0"
        class="mt-10 rounded-full border border-white px-4 py-2 text-white text-sm font-semibold hover:bg-white hover:text-black"
        (click)="goToPreviousQuestion()"
      >
        <span aria-hidden="true">←</span>Previous
      </button>
      <button
        *ngIf="this.currentQuestionIndex < this.quiz.questions.length - 1"
        class="mt-10 rounded-full border border-white px-4 py-2 text-white text-sm font-semibold hover:bg-white hover:text-black"
        (click)="goToNextQuestion()"
      >
        Next <span aria-hidden="true">→</span>
      </button>

      <button
        *ngIf="this.currentQuestionIndex === this.quiz.questions.length - 1"
        class="mt-10 rounded-full border border-white px-4 py-2 text-white text-sm font-semibold hover:bg-white hover:text-black"
        (click)="handleQuizFinish()"
      >
        Finish <span aria-hidden="true">✓</span>
      </button>
    </div>
  </div>

  <div
    *ngIf="!isQuizOpen"
    class="max-w-3xl w-full bg-gradient-to-r from-customDarkBlue to-customBlue rounded-lg p-8 shadow-md"
  >
    <div class="text-white text-center text-xl mb-4">
      You scored {{ score }} / {{ getTotalScorePoints() }}
    </div>
    <div class="flex justify-center items-center">
      <div
        class="flex items-center justify-center bg-white text-customDarkBlue rounded-full h-16 w-16"
      >
        <span class="text-3xl font-bold"
          >{{ ((score / getTotalScorePoints()) * 100).toFixed(0) }}%</span
        >
      </div>
      <div class="ml-4">
        <div class="text-white">Your score</div>
        <div class="text-sm text-gray-300">
          Out of {{ getTotalScorePoints() }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex items-center justify-center mt-4" *ngIf="!isComponentLoaded">
  <div
    class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"
  ></div>
</div>