<nav class="bg-navbarFooterBlue">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <button type="button" (click)="toggleMobileMenu()" class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span class="sr-only">Open main menu</span>
          <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'hidden': mobileMenuOpen, 'block': !mobileMenuOpen}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" [ngClass]="{'block': mobileMenuOpen, 'hidden': !mobileMenuOpen}">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class=" hidden sm:ml-6 sm:block flex flex-shrink-0 items-center">
        <a routerLink="/about">
          <img class="h-auto w-auto " src="../../assets/logo2.png" alt="Your Company">
        </a>
      </div>
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="hidden  sm:block">
          <div class="flex space-x-4">
            <a routerLink="/courses" *ngIf="user.isLoggedIn$ | async" class="rounded-md px-3 py-2 text-sm font-medium text-white" aria-current="page">Courses</a>
          </div>
        </div>
      </div>
      
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <div class="hidden sm:ml-6 sm:block flex flex-shrink-0 items-center mr-2">
            <a href="https://whop.com/harmonicfxpro/" target="_blank" class="rounded-md px-3 py-2 text-sm font-medium text-white">
              Susbscribe
            </a>
          </div>
          <div class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <a routerLink="/login" *ngIf="user.isLoggedOut$ | async">Log in</a>
            <a routerLink="/login" *ngIf="user.isLoggedOut$ | async"></a>
            <a href="#" *ngIf="user.isLoggedIn$ | async" (click)="logout()">Logout</a>    
          </div>
      </div>
    </div>
  </div>
  <div class="sm:hidden" id="mobile-menu" [ngClass]="{'block': mobileMenuOpen, 'hidden': !mobileMenuOpen}">
    <div class="space-y-1 px-2 pb-3 pt-2">

      <a routerLink="/courses" class="flex items-center space-x-2 rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
        <img class="h-10 w-auto" src="../../assets/learning.png" alt="Logo">
        <span>Courses</span>
      </a>
      <!-- <a routerLink="/create-user" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Create user</a> -->
      
      <a routerLink="/about" class="flex items-center space-x-2 rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
        <img class="h-10 w-25" src="../../assets/logo2.png" alt="Logo">
        <span>About us</span>
      </a>
      
      <!-- <a routerLink="/login" *ngIf="user.isLoggedOut$ | async" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Register</a> -->
      
      <a routerLink="/login" *ngIf="user.isLoggedOut$ | async" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Login</a>
      <!-- <a href="https://discord.gg/" target="_blank" *ngIf="user.isLoggedIn$ | async" class="flex items-center space-x-2 rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
        <img class="h-10 w-auto" src="../../assets/discord.png" alt="Discord">
        <span>Discord</span>
      </a> -->
    
      <a href="https://whop.com/harmonicfxpro/" target="_blank" class="flex items-center space-x-2 rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
        <span>Subscribe now!</span>
      </a>
      <!-- <a href="#" *ngIf="user.isLoggedIn$ | async" (click)="logout()" class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Logout</a> -->
    </div>
  </div>
  <div class="border-t border-solid border-blue-500"></div>
</nav>

