import { Quiz } from "../model/quiz-models/quiz.model";




export const quiz = {
  "id": "quiz11",
  "courseId":"course11",
  "title": "Quiz",
  "passingScore": 3,
  "questions": [
    {
      "id": "q1",
      "text": "Is this a bearish pattern? ",
      "answers": [
        {"id": "a1", "text": "Yes", "isCorrect": true},
        {"id": "a2", "text": "No", "isCorrect": false}
      ],
      "scorePoints": 1
    },
    {
      "id": "q2",
      "text": "Should you wait for breakout and retest or just the breakout is enough? ",
      "answers": [
        {"id": "a1", "text": "No, only a breakout", "isCorrect": false},
        {"id": "a2", "text": "Both ", "isCorrect": true},
        {"id": "a3", "text": "No, only the retest", "isCorrect": false}
      ],
      "scorePoints": 1
    },
    {
      "id": "q3",
      "text": "Where shall the stops be placed on this pattern? ",
      "answers": [
        {"id": "a1", "text": "Just above the last candle before the breakout", "isCorrect": true},
        {"id": "a2", "text": "Just under the last candle before the breakout", "isCorrect": false}
      ],
      "scorePoints": 1
    },
    {
      "id": "q4",
      "text": "How many targets can we have for the pattern?",
      "answers": [
        {"id": "a1", "text": "Multiple of the first unit, everytime 1 unit is exceeded", "isCorrect": true},
        {"id": "a2", "text": "Only one unit", "isCorrect": false}
      ],
      "scorePoints": 1
    }
  ],
  "timeToSolve": 5
} as Quiz;


// export const quiz = {
//     "id": "quiz1",
//     "courseId":"Z2LLYdg9y9nSOXSE4s5e",
//     "title": "Quiz",
//     "passingScore": 5,
//     "questions": [
//       {
//         "id": "q1",
//         "text": "What is a support/resistence?",
//         "answers": [
//           {"id": "a1", "text": "a pattern", "isCorrect": false},
//           {"id": "a2", "text": "a line", "isCorrect": false},
//           {"id": "a3", "text": "an area/zone", "isCorrect": true},
//           {"id": "a4", "text": "a soup", "isCorrect": false}
//         ],
//         "scorePoints": 1
//       },
//       {
//         "id": "q2",
//         "text": "How to avoid market makers hunting of SL?",
//         "answers": [
//           {"id": "a1", "text": "having the  sl a little bit under the resistence", "isCorrect": false},
//           {"id": "a2", "text": "having the sl over the support over the support", "isCorrect": false},
//           {"id": "a3", "text": "wider stop loss", "isCorrect": true},
//           {"id": "a4", "text": " not trading", "isCorrect": false}
//         ],
//         "scorePoints": 1
//       },
//       {
//         "id": "q3",
//         "text": "How you can calculate in advance the support/resistence?",
//         "answers": [
//           {"id": "a1", "text": "using harmonic patterns", "isCorrect": true},
//           {"id": "a2", "text": "you can't", "isCorrect": false},
//           {"id": "a3", "text": "hecking on twitter what your fav influencer is saying", "isCorrect": true},
//           {"id": "a4", "text": "whatever", "isCorrect": false}
//         ],
//         "scorePoints": 1
//       },
//       {
//         "id": "q4",
//         "text": "Who wrote 'Hamlet'?",
//         "answers": [
//           {"id": "a1", "text": "Charles Dickens", "isCorrect": false},
//           {"id": "a2", "text": "William Shakespeare", "isCorrect": true},
//           {"id": "a3", "text": "Mark Twain", "isCorrect": false},
//           {"id": "a4", "text": "Jane Austen", "isCorrect": false}
//         ],
//         "scorePoints": 1
//       }
//     ],
//     "timeToSolve": 15
//   } as Quiz;
  