import { Component, Input, OnInit } from "@angular/core";
import { Quiz } from "../model/quiz-models/quiz.model";
import { Question } from "../model/quiz-models/question.model";
import { Answer } from "../model/quiz-models/answer.model";
import { Subscription, interval } from "rxjs";
import { UserService } from "../service/user.service";
import { QuizService } from "../service/quiz.service";
import { ActivatedRoute } from "@angular/router";

interface TimeFrame {
  minutes: number;
  seconds: number;
}

@Component({
  selector: "app-quiz",
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.css"],
})
export class QuizComponent implements OnInit {
  score: number = 0;
  currentQuestionIndex: number = 0;
  private userId!: string;
  remainingTime: number;
  private timerSubscription: Subscription;
  @Input() quiz!: Quiz;
  isQuizOpen: boolean = false;
  currentQuestion!: Question;
  isComponentLoaded: boolean = false;

  constructor(
    private userService: UserService,
    private quizService: QuizService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const courseId = params["courseId"];
      this.quizService.getQuizByCourseId(courseId).subscribe((result) => {
        this.userService.currentUser$.subscribe((user) => {
          this.userId = user.uid;
          this.quiz=result;
          const timeFrame = this.handleQuizStart();
          if (timeFrame.minutes < 0 || timeFrame.seconds < 0) {
            this.startTimer(timeFrame);
            this.handleQuizFinish();
            this.isComponentLoaded = true;
            return;
          }
          this.startTimer(timeFrame);
          
          this.currentQuestion = this.quiz.questions[this.currentQuestionIndex];
          this.isQuizOpen = true;
          this.isComponentLoaded = true;
        });
      });
    });
  }

  handleQuizStart(): TimeFrame {
    const quizInProgressEndTime = localStorage.getItem(
      this.getQuizKey(this.userId)
    );
    if (quizInProgressEndTime === null) {
      const newDate = new Date(); 
      newDate.setMinutes(newDate.getMinutes() + this.quiz.timeToSolve);
      localStorage.setItem(this.getQuizKey(this.userId), newDate.toISOString());
      return { minutes: this.quiz.timeToSolve, seconds: 0 } as TimeFrame;
    }
    const endTime = new Date(quizInProgressEndTime);
    if (endTime <= new Date()) return { minutes: -1, seconds: -1 } as TimeFrame;
    return this.getTimeDifferenceToNow(endTime);
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  selectAnswer = (answer: Answer): void => {
    this.currentQuestion.answers.forEach((ans) => (ans.isSelected = false));
    answer.isSelected = true;
  };

  goToNextQuestion = (): void => {
    if (this.currentQuestionIndex === this.quiz.questions.length - 1) return;
    this.currentQuestionIndex += 1;
    this.currentQuestion = this.quiz.questions[this.currentQuestionIndex];
  };

  goToPreviousQuestion = (): void => {
    if (this.currentQuestionIndex === 0) return;
    this.currentQuestionIndex -= 1;
    this.currentQuestion = this.quiz.questions[this.currentQuestionIndex];
  };

  startTimer(timeFrame: { minutes: number; seconds: number }) {
    this.remainingTime = timeFrame.minutes * 60 + timeFrame.seconds; // Convert minutes to seconds

    this.timerSubscription = interval(1000).subscribe(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.handleQuizFinish();
      }
    });
  }
  get formattedTime() {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  getTimeDifferenceToNow(end: Date): TimeFrame {
    const start = new Date();

    const timeDiff = end.getTime() - start.getTime();

    const diffInSeconds = timeDiff / 1000;

    const minutes = Math.floor(diffInSeconds / 60);
    const seconds = Math.floor(diffInSeconds % 60);

    return { minutes, seconds } as TimeFrame;
  }
  getQuizKey = (userId: string): string => `${userId}-${this.quiz.id}`;

  handleQuizFinish = () => {
    this.quiz.questions.forEach((question) => {
      if (
        question.answers.filter(
          (answer) => answer.isCorrect && answer.isSelected
        ).length > 0
      )
        this.score += question.scorePoints;
    });
    localStorage.removeItem(this.getQuizKey(this.userId));
    this.timerSubscription.unsubscribe();
    this.isQuizOpen = false;
  };

  getTotalScorePoints = (): number => {
    const quizPoints = this.quiz.questions.map((q) => q.scorePoints);
    let result = 0;
    for (let index = 0; index < quizPoints.length; index++) {
      result += quizPoints[index];
    }
    return result;
  };
}
