import {Component, OnInit} from '@angular/core';


import 'firebase/firestore';

import {AngularFirestore} from '@angular/fire/firestore';
import {COURSES, findLessonsForCourse} from './db-data';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit{

    constructor(
    private db: AngularFirestore,
    private sanitizer: DomSanitizer,
    ) {
    }
    actualUrl1: SafeResourceUrl;
    actualUrl2: SafeResourceUrl;
    actualUrl3: SafeResourceUrl;
    actualUrl4: SafeResourceUrl;
    actualUrl5: SafeResourceUrl;
    actualUrl6: SafeResourceUrl;
    actualUrl7: SafeResourceUrl;
    actualUrl8: SafeResourceUrl;

    ngOnInit() {
        this.actualUrl1 = this.sanitizer.bypassSecurityTrustResourceUrl(
          "https://www.youtube.com/embed/BfRUbYOQ0II"
        );
        this.actualUrl2 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/3kA9Xx8Y54I"
          );
          this.actualUrl3 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/MPUtLCHQVdU"
          );
          this.actualUrl4 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/tJNypv7KUUE"
          );
          this.actualUrl5 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/BPs-ZuGQdso"
          );
          this.actualUrl6 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/95gz1rbl31o"
          );
          this.actualUrl7 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/nvjCt7WQX_w"
          );
          this.actualUrl8 = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://www.youtube.com/embed/hsrwB5wYkh8"
          );
    }


    async uploadData() {
        const coursesCollection = this.db.collection('courses');
        const courses = await this.db.collection('courses').get();
        for (let course of Object.values(COURSES)) {
            const newCourse = this.removeId(course);
            const courseRef = await coursesCollection.add(newCourse);
            const lessons = await courseRef.collection('lessons');
            const courseLessons = findLessonsForCourse(course['id']);
            for (const lesson of courseLessons) {
                const newLesson = this.removeId(lesson);
                delete newLesson.courseId;
                await lessons.add(newLesson);
            }
        }
    }

    removeId(data: any) {
        const newData: any = {...data};
        delete newData.id;
        return newData;
    }

    onReadDoc() {
        this.db.doc("/courses/4IZuNUgaXApjvZRZbsho").get().subscribe(snap=> {
        });
    }

    onReadDBCollection() {
        this.db.collection("courses",
            ref => ref.where("seqNo", "<=", 5)
            .where("url", "==", "angular-forms-course")
            .orderBy("seqNo")
        ).get().subscribe(snaps => {
           snaps.forEach(snap => {
           })
        });
    }


}
















