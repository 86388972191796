<app-navbar></app-navbar>
  <div class="custom-background flex items-center justify-center " >
    <div class="w-full min-w-screen p-8 sm:p-12 bg-transparent shadow-xl min-h-screen "  >
          <router-outlet></router-outlet>
          
    </div>
</div>
<app-footer></app-footer>






