<div class="courses-panel bg-transparent text-white">
    <div class="header">
        <h2 class="title text-white text-2xl">All Courses</h2>
        <ng-container *ngIf="(userService.roles$ | async) as roles">
            <ng-container *ngIf="roles.admin">
                <button mat-mini-fab color="accent" routerLink="/create-course">
                    <mat-icon class="add-course-btn">add</mat-icon>
                </button>
            </ng-container >
        </ng-container>
    </div>

    <mat-tab-group >
      <mat-tab >
            <ng-template mat-tab-label>
              <span class="text-white margin-left" >Beginners</span>
            </ng-template>
            <courses-card-list [courses]="beginnersCourses$ | async"
                               (courseEdited)="reloadCourses()"
                               (courseDeleted)="reloadCourses()">
            </courses-card-list>
          </mat-tab>

          <mat-tab >
            <ng-template mat-tab-label>
              <span class="text-white">Intermediate</span>
            </ng-template>
            <courses-card-list [courses]="intermediateCourses$ | async"
                               (courseEdited)="reloadCourses()"
                               (courseDeleted)="reloadCourses()">
            </courses-card-list>
          </mat-tab>
          
          <mat-tab >
            <ng-template mat-tab-label>
              <span class="text-white ">Advanced</span>
            </ng-template>
            <courses-card-list [courses]="advancedCourses$ | async" (courseEdited)="reloadCourses()" (courseDeleted)="reloadCourses()">
            </courses-card-list>
          </mat-tab>

    </mat-tab-group>

    
</div>
