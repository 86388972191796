import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Quiz } from '../model/quiz-models/quiz.model';
import { convertSnaps } from './db-utils';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { quiz } from '../quiz/quiz.mock';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private db: AngularFirestore) { }

  public getQuizByCourseId = (courseId: string) : Observable<Quiz> => {
    return this.db.collection('quizes', (ref) => ref.where("courseId", "==", courseId).limit(1))
    .get()
    .pipe(map(result => convertSnaps<Quiz>(result)[0]));
  }

  public async loadDataQuiz() {
    await this.db.collection('quizes').add(quiz);
  }
}
