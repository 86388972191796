import {Component, OnInit} from '@angular/core';
import { UserService } from './service/user.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(public user: UserService
  ) {

  }
  title = 'my-app';

  ngOnInit() {
    
  }
  isDarkModeActive: boolean = true; // poți inițializa cu true dacă vrei să fie activat dark mode inițial
  
  toggleDarkMode() {
    this.isDarkModeActive = !this.isDarkModeActive;
  }

  logout() {
    this.user.logout();
  }

}
