export const COURSES: any = {

  5: {
    id: 5,

    description: 'Test',
    longDescription: 'Test',
    iconUrl: 'https://angular-academy.s3.amazonaws.com/thumbnails/angular2-for-beginners-small-v2.png',
    courseListIcon: 'https://angular-academy.s3.amazonaws.com/main-logo/main-page-logo-small-hat.png',
    categories: ['BEGINNER'],
    lessonsCount: 10,
    seqNo: 7,
    url: 'angular-for-beginners',
    price: 50
  },
};

export const LESSONS = {

  10: {
    id: 10,
    'description': ' Directives - Inputs, Output Event Emitters and How To Export Template References',
    'duration': '4:01',
    'seqNo': 10,
    courseId: 5
  },

};


export function findCourseById(courseId: number) {
  return COURSES[courseId];
}

export function findLessonsForCourse(courseId: number) {
  return Object.values(LESSONS).filter(lesson => lesson.courseId == courseId);
}

