import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Course } from "../model/course";
import { finalize, tap } from "rxjs/operators";
import { Lesson } from "../model/lesson";
import { CoursesService } from "../service/courses.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { QuizService } from "../service/quiz.service";
import { quiz } from "../quiz/quiz.mock";

@Component({
  selector: "course",
  templateUrl: "./course.component.html",
  styleUrls: ["./course.component.css"],
})
export class CourseComponent implements OnInit {
  loading = false;
  course: Course;
  lessons: Lesson[];

  lastLoadPagged = 0;

  displayedColumns = ["seqNo", "description", "duration"];

  listOfURL = [];

  actualMovie: number = 0;
  numberOfVideos :  number = 0;
  iframeLoaded: boolean = false;
  actualUrl: SafeResourceUrl;
  constructor(
    private route: ActivatedRoute,
    private courseService: CoursesService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private quizService: QuizService
  ) {
    
  }

  ngOnInit() {
    this.course = this.route.snapshot.data["course"];
    this.numberOfVideos = this.course.videos.length - 1;
    this.loading = true;

    this.listOfURL = this.course.videos;
    this.actualUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.listOfURL[this.actualMovie]
    );

    // this.quizService.loadDataQuiz();
    this.courseService
      .findLessons(this.course.id)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((lessons) => (this.lessons = lessons));
  }

  nextMovie() { // Replace with your new URL or logic to fetch a new URL
    this.iframeLoaded = false;
    if (this.actualMovie > this.numberOfVideos) return;
    this.actualMovie++;
    this.actualUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.listOfURL[this.actualMovie]
    );
  }

  previousMovie() { // Replace with your new URL or logic to fetch a new URL
    this.iframeLoaded = false;
    this.actualMovie--;
    this.actualUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.listOfURL[this.actualMovie]
    );
  }

  goToQuiz = (course: Course): void => {
    const route = `quiz/${course.id}`;
    this.router.navigate([route]);
  };

  loadMore() {
    this.lastLoadPagged++;

    this.loading = true;

    this.courseService
      .findLessons(this.course.id, "asc", this.lastLoadPagged)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (lessons) => (this.lessons = this.lessons.concat(this.lessons))
      );
  }

  onIframeLoad(): void {
    this.iframeLoaded = true;
  }
}
