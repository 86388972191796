import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import * as firebaseui from 'firebaseui';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import firebase from 'firebase/app';
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    ui: firebaseui.auth.AuthUI;
    firebaseUiAuthConfig: firebaseui.auth.Config;
    constructor(private afAuth: AngularFireAuth, 
      private router: Router
    ) {
        this.firebaseUiAuthConfig = {
        signInFlow: 'popup',
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        callbacks: {
          signInSuccessWithAuthResult: this.onLoginSuccessful.bind(this)
        }
      };
      this.ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
  
    ngOnInit(): void {
      this.ui.start('#firebaseui-auth-container', this.firebaseUiAuthConfig);
    }
  
    private onLoginSuccessful(result) {
      this.router.navigateByUrl("/courses");
    }
  }

