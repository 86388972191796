<div
  *ngIf="!loading"
  class="max-w-3xl mx-auto mt-8 p-6 bg-gradient-to-r from-customDarkBlue to-customBlue rounded-lg shadow-lg text-white flex justify-center flex-col"
>
  <h2 class="text-3xl font-bold mb-4 text-center">{{ course?.description }}</h2>

  <div class="flex p-4 justify-center align-center" [class.hidden]="!iframeLoaded">
    <iframe
      id="player"
      class="rounded-lg shadow-lg"
      [src]="actualUrl"
      frameborder="0"
      width="440"
      height="350"
      style="max-width: 100%; max-height: 100%; border-radius: 12px"
      allowfullscreen
      class="w-full"
      (load)="onIframeLoad()"
    ></iframe>
  </div>

  <div class="flex items-center justify-center mt-4" *ngIf="!iframeLoaded">
    <div
      class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"
    ></div>
  </div>



  <div class="p-4">
    <div class="flex justify-center items-center space-x-4">
      <button
        class="glass-effect text-xl bg-customBlue hover:bg-customLightBlue text-white py-2 px-4 rounded-lg transition-transform duration-300 ease-in-out"
        (click)="previousMovie()"
        [class.hidden]="actualMovie === 0"
      >
        ← Previous
      </button>

      <button
        class="glass-effect text-xl bg-customLightBlue hover:bg-customBlue text-white py-2 px-4 rounded-lg transition-transform duration-300 ease-in-out"
        (click)="nextMovie()"
        [class.hidden]="actualMovie === numberOfVideos"
      >
        Next →
      </button>

      <button
        class="glass-effect text-xl bg-customLightBlue hover:bg-customBlue text-white py-2 px-4 rounded-lg transition-transform duration-300 ease-in-out"
        (click)="goToQuiz(course)"
        [class.hidden]="actualMovie !== numberOfVideos"
      >
        Quiz ?
      </button>
    </div>
  </div>
</div>

<div class="flex items-center justify-center mt-4" *ngIf="loading">
  <div
    class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"
  ></div>
</div>
