<div class="text-center">
  <span class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Welcome to Harmonic Pro Trader</span>
</div>
<br>
    
<div class="max-w-screen-lg mx-auto p-8">
  <div class="text-center">
    <h1 class="tracking-tight text-white mb-4 text-2xl">
      Harmonic Pro Trader is a community built around education. When inside the community you will get access to world class traders - who you can message, voice-chat with, and learn from
    </h1>
    <h2 class="tracking-tight text-white text-2xl">
      We trade live
    </h2>
    <h2 class="tracking-tight text-white text-2xl">
      We teach
    </h2>
    <h2 class="tracking-tight text-white text-2xl">
      We build educationally solid traders 💰
    </h2>
  </div>
  
  
  <div class="max-w-screen-lg mx-auto p-8">
    <div class="flex items-center justify-center lg:justify-center space-x-6">
      <!-- Iconuri -->
       <a href="https://www.instagram.com/harmonicprotrader_/" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-white" viewBox="0 0 24 24">
          <!-- Icon pentru Instagram -->
          <path d="M12 2.5c3.1 0 3.4.1 4.6.2 1.1.1 2 .6 2.8 1.4.8.8 1.3 1.7 1.4 2.8.1 1.2.2 1.5.2 4.6s-.1 3.4-.2 4.6c-.1 1.1-.6 2-1.4 2.8-.8.8-1.7 1.3-2.8 1.4-1.2.1-1.5.2-4.6.2s-3.4-.1-4.6-.2c-1.1-.1-2-.6-2.8-1.4-.8-.8-1.3-1.7-1.4-2.8-.1-1.2-.2-1.5-.2-4.6s.1-3.4.2-4.6c.1-1.1.6-2 1.4-2.8.8-.8 1.7-1.3 2.8-1.4 1.2-.1 1.5-.2 4.6-.2zM12 0C8.3 0 7.8.1 6.6.2 5.5.3 4.6.8 3.8 1.6S2.3 3.5 2.2 4.4C2.1 5.6 2 6 2 12s.1 6.4.2 7.6c.1 1.1.6 2 .8 2.8s.8 1.5 1.6 2.3c.8.8 1.7 1.3 2.8 1.4 1.2.1 1.5.2 4.6.2s3.4-.1 4.6-.2c1.1-.1 2-.6 2.8-1.4.8-.8 1.3-1.7 1.4-2.8.1-1.2.2-1.5.2-4.6s-.1-3.4-.2-4.6c-.1-1.1-.6-2-1.4-2.8-.8-.8-1.7-1.3-2.8-1.4-1.2-.1-1.5-.2-4.6-.2zm0 5.5c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5zm0 7c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.3 3 3 3z"/>
        </svg>
       </a>

       <a href="https://www.facebook.com/profile.php?id=61565000881286" target="_blank">
       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-white" viewBox="0 0 24 24">
        <!-- Icon pentru Facebook -->
        <path fill-rule="evenodd" d="M12 2.04C6.477 2.04 2.04 6.477 2.04 12c0 4.968 3.657 9.062 8.437 9.88v-6.99h-2.54V12h2.54v-1.64c0-2.507 1.493-3.89 3.788-3.89 1.1 0 2.245.195 2.245.195v2.47h-1.265c-1.247 0-1.633.775-1.633 1.57V12h2.78l-.444 2.89h-2.336v6.99C18.304 21.062 22 16.968 22 12c0-5.523-4.477-9.96-10-9.96z"/>
      </svg>
      </a>
    
      <a href="https://x.com/HarmonicFxPro" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-white" viewBox="0 0 24 24">
        <!-- Icon pentru Twitter -->
        <path fill-rule="evenodd" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.611 1.798-1.577 2.165-2.724-.951.564-2.005.974-3.127 1.195-.896-.954-2.173-1.55-3.588-1.55-2.717 0-4.92 2.204-4.92 4.92 0 .385.044.761.127 1.122C7.691 8.095 4.066 6.13 1.64 3.161c-.423.726-.666 1.571-.666 2.475 0 1.71.87 3.223 2.188 4.107-.807-.026-1.566-.248-2.228-.617v.062c0 2.388 1.698 4.384 3.95 4.835-.413.113-.849.173-1.296.173-.317 0-.626-.031-.928-.089.627 1.956 2.444 3.379 4.6 3.419-1.685 1.32-3.809 2.107-6.115 2.107-.398 0-.79-.023-1.176-.069 2.179 1.397 4.768 2.213 7.548 2.213 9.057 0 14.01-7.504 14.01-14.01 0-.213-.005-.426-.014-.637.961-.694 1.797-1.562 2.457-2.549z"/>
      </svg>
      </a>

      <a href="https://www.tiktok.com/@harmonicprotrader?_t=8pNqVPiSBMX&_r=1" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-white" viewBox="0 0 24 24">
        <!-- Icon pentru TikTok -->
        <path fill-rule="evenodd" d="M12.753 2h2.49c.008.146.013.292.013.44 0 2.026 1.64 3.664 3.66 3.664.356 0 .7-.051 1.025-.146v2.492a6.119 6.119 0 0 1-3.683-1.223v5.805c0 3.87-3.128 7.013-6.992 7.013a6.987 6.987 0 0 1-4.319-1.488 6.958 6.958 0 0 1-2.673-5.526c0-3.86 3.122-7.003 6.982-7.003.266 0 .53.018.79.052v2.51a3.497 3.497 0 0 0-.79-.09c-1.932 0-3.503 1.574-3.503 3.514 0 1.937 1.571 3.516 3.503 3.516 1.936 0 3.511-1.574 3.511-3.516V2z"/>
      </svg>
      </a>

    </div>
    
    <div class="mt-10 flex flex-col items-center justify-center gap-4 sm:flex-row sm:gap-x-6">
      <a href="/courses" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        Get started with our courses
      </a>
      <a href="#" class="rounded-full border border-white px-4 py-2 text-white text-sm font-semibold hover:bg-white hover:text-black">
        Learn more about us <span aria-hidden="true">→</span>
      </a>
    </div>
    
  </div>
</div>

<div class="max-w-screen-xl mx-auto mb-10">
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"> <!-- Ajustat grid-cols și gap pentru diferite dimensiuni -->
    <!-- Card 1 -->
    <div class="bg-navbarFooterBlue glass-effect border border-white rounded-lg shadow-lg overflow-hidden p-4 mx-auto sm:p-3 w-full sm:max-w-full min-h-[550px] max-w-md flex flex-col">
      <img src="../../assets/poza1.png" alt="Imagine" class="w-full h-48 object-cover">
      <div class="mt-4 flex flex-col flex-grow">
        <h2 class="text-2xl md:text-3xl font-bold text-white mb-2">Trading Made Simple</h2>
        <p class="text-base md:text-lg text-gray-300 mb-4">Learn to master trading and grow your portfolio with a lot of video lessons, signals and a lot of
          interactive meetings and webinars</p>
        <button class="pulse-button mt-auto" ><a href="https://whop.com/harmonicfxpro/?pass=prod_P5KQ1aijc908k" target="_blank">Join Now</a></button>
      </div>
    </div>

    <!-- Card 2 -->
    <div class="bg-navbarFooterBlue glass-effect border border-white rounded-lg shadow-lg overflow-hidden p-4 mx-auto sm:p-3 w-full sm:max-w-full min-h-[550px] max-w-md flex flex-col">
      <img src="../../assets/poza2.png" alt="Imagine" class="w-full h-48 object-cover ">
      <div class="mt-4 flex flex-col flex-grow">
        <h2 class="text-2xl md:text-3xl font-bold text-white mb-2">Level up your portfolio</h2>
        <p class="text-base md:text-lg text-gray-300 mb-4">Trade confidently with strategies tailored to your goals, advanced
          risk management, and a clear understanding of risk vs. reward and
          position sizing.</p>
        <button class="pulse-button mt-auto"><a href="https://whop.com/harmonicfxpro/?pass=prod_O5gHWVVRN3HPp" target="_blank">Join Now</a></button>
      </div>
    </div>

    <!-- Card 3 -->
    <div class="bg-navbarFooterBlue glass-effect border border-white rounded-lg shadow-lg overflow-hidden p-4 mx-auto sm:p-3 w-full sm:max-w-full min-h-[550px] max-w-md flex flex-col">
      <img src="../../assets/poza3.png" alt="Imagine" class="w-full h-48 object-cover">
      <div class="mt-4 flex flex-col flex-grow">
        <h2 class="text-2xl md:text-3xl font-bold text-white mb-2">Get the ultimate edge</h2>
        <p class="text-base md:text-lg text-gray-300 mb-4">Helping you make gains through a bulletproof system. Beat Wall
          Street at its own game, trading on data instead of emotion.</p>
        <button class="pulse-button mt-auto"><a href="https://whop.com/harmonicfxpro/?pass=prod_pPmshTSyoGHI7" target="_blank">Join Now</a></button>
      </div>
    </div>
  </div>
</div>

<div class="max-w-screen-xl mx-auto">
  <style>
    @media (max-width: 768px) {
      iframe {
        width: 360px;
        height: 440px;
      }
    }
  </style>
  <div class="rounded-lg border border-white shadow-lg overflow-hidden"> 
    <div class="text-center">
      <h1 class="tracking-tight text-white text-2xl p-8">
        Check out our Live Trades and Pattern Trading Techniques
      </h1>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-items-center">
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <div class="text-center">

          </div>
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl1"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      
      <!-- Repeți pentru fiecare iframe -->
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl2"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl3"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl4"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl5"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl6"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl7"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      <div class="w-full flex flex-col p-4">
        <div class="w-full flex justify-center align-center">
          <iframe
            id="player"
            class="rounded-lg shadow-lg"
            frameborder="0"
            [src]="actualUrl8"
            width="320"
            height="300"
            style="max-width: 100%; max-height: 100%; border-radius: 12px;"
            allowfullscreen
            class="w-full"
          ></iframe>
        </div>
      </div>
      
      <!-- Repetă celelalte 6 iframe-uri în același mod -->
      
    </div>
  </div>
</div>








<!-- <div class="max-w-screen-lg mx-auto flex flex-col-reverse lg:flex-row items-center justify-between p-8">
  <div class="flex-1 mb-8 lg:mb-0 lg:ml-8">
    <h2 class="text-3xl font-bold mb-4">Titlul textului</h2>
    <p class="text-lg text-gray-700 mb-4 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a lorem eget sapien lacinia porttitor. Maecenas volutpat metus vitae arcu suscipit, nec malesuada lectus scelerisque. Integer non lectus vel libero mollis ullamcorper. Nunc nec sagittis libero. Duis ac ultrices ante, id vestibulum ante.</p>
    <a href="#" class="text-blue-500 hover:underline">Află mai multe</a>
  </div>
  <div class="flex-1">
    <img src="https://spotlight.tailwindui.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fimage-3.454151b1.jpg&w=384&q=75" alt="Imagine" class="w-full h-auto rounded-lg shadow-lg mb-4 lg:mb-0">
  </div>
</div> -->



