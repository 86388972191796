<div class="max-w-screen-xl mx-auto mb-10 create-course bg-white p-12 rounded">

  <h3>Create New Course</h3>

  <form [formGroup]="form">

    <mat-form-field appearance="outline">

      <input placeholder="Course title"  matInput formControlName="description">

    </mat-form-field>

    <div class="course-image-upload">

      <span>Course thumbnail:</span>

      <img class="course-thumbnail mat-elevation-z3">

    </div>

    <mat-form-field appearance="outline" >

      <mat-select placeholder="Select category" formControlName="category">
        <mat-option value="BEGINNER">Beginner</mat-option>
        <mat-option value="INTERMEDIATE">Intermediate</mat-option>
        <mat-option value="ADVANCED">Advanced</mat-option>
      </mat-select>

    </mat-form-field>

    <mat-form-field appearance="outline">

      <input placeholder="Course url" matInput formControlName="url">

    </mat-form-field>

    <mat-form-field appearance="outline" >

      <textarea matInput placeholder="Course description"  formControlName="longDescription"></textarea>

    </mat-form-field>

    <mat-slide-toggle class="promo" color="primary"  formControlName="promo">
      Course in promotion?
    </mat-slide-toggle>

    <mat-form-field appearance="outline">
      <mat-label>Promotion start date</mat-label>
      <input matInput [matDatepicker]="promoStartPicker"  formControlName="promoStartAt">
      <mat-datepicker-toggle matSuffix [for]="promoStartPicker"></mat-datepicker-toggle>
      <mat-datepicker #promoStartPicker></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button color="warn" [disabled]="!form.valid"
    (click)="onCreateCourse()">
      Create Course
    </button>

  </form>

</div>