// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useEmulators: false,
  firebase : {
    apiKey: "AIzaSyDrFTgfiHNtSMWjgKvwej6zMWCi9tuvIs4",
    authDomain: "harmonicprotrader.com",
    projectId: "tradingapplication-fae86",
    storageBucket: "tradingapplication-fae86.appspot.com",
    messagingSenderId: "553805700520",
    appId: "1:553805700520:web:4295f3eff25f81d3b1d19d",
    measurementId: "G-Q9HLXDMJT6"
  },
  api: {},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
