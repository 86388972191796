<div class="border-t border-solid border-blue-500"></div>
   <footer class="bg-navbarFooterBlue text-white py-4">
    <div class="max-w-screen-xl mx-auto px-2">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- Partea stângă - Contactează-ne -->
        <div class="md:col-span-1  ">
            <h2 class="text-2xl font-bold mb-2">
              <a href="https://www.tradewithtitans.com/terms" target="" class="text-gray-400 hover:text-white transition duration-300">
                  Privacy Policy
              </a>
            </h2>
            <h2 class="text-2xl font-bold mb-2">
              <a href="https://www.tradewithtitans.com/terms" target="_blank" class="text-gray-400 hover:text-white transition duration-300">
                Terms of Service
              </a>
            </h2>
            <h2 class="text-2xl font-bold mb-2">
              <a href="https://www.tradewithtitans.com/terms" target="_blank" class="text-gray-400 hover:text-white transition duration-300">
                Harmonic Pro Trader
              </a>
            </h2>
        </div>
        
        <!-- Partea dreaptă - Urmează-ne -->
        <div class="md:col-span-1">
          <p class="text-gray-300">
            © 2024 Harmonic Pro Trader. All rights reserved. Disclaimer: Harmonic Pro Trader is an online course for entertainment purposes only. All opinions expressed by the hosts, guests and videos should not be construed as financial advice. Views expressed by guests and hosts do not reflect the views of Harmonic Pro Trader. Listeners are encouraged to do their own research.</p>
        </div>
      </div>
      <div class="text-center mt-8">
        <a class="text-gray-400" href="https://www.tradewithtitans.com/terms">© Harmonic Pro Trader</a>
        
      </div>
    </div>
  </footer>
  
  