<!-- <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
  <div *ngFor="let course of courses" class="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
    <img [src]="course.iconUrl" alt="{{ course.description }}" class="w-full h-48 object-cover">
    <div class="p-6">
      <h2 class="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">{{ course.description }}</h2>
      <p class="text-gray-700 dark:text-gray-300 text-base">{{ course.longDescription }}</p>
    </div>
    <div class="p-6 pt-4 mt-auto">
      <a [routerLink]="['/courses', course.url]" class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full text-center block mb-2 w-full">VIEW COURSE</a>
      <ng-container *ngIf="(userService.roles$ | async) as roles">
        <ng-container *ngIf="roles.admin">
          <button (click)="editCourse(course)" class="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-full text-center block mb-2 w-full">EDIT</button>
          <button (click)="deleteCourse(course)" class="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full text-center block w-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
            DELETE
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>  -->
<div class="max-w-screen-lg mx-auto p-1">
  <div class="grid grid-cols-1 gap-6">
    <div *ngFor="let course of courses" class="bg-white glass-effect dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
      <div class="lg:flex">
        <!-- Imaginea -->
        <img [src]="course.iconUrl" alt="{{ course.description }}" class="w-full h-full lg:w-1/2 lg:h-auto object-cover lg:object-contain">

        <!-- Textul și butoanele -->
        <div class="p-6 flex flex-col justify-between lg:flex-1">
          <!-- Titlul și descrierea -->
          <h2 class="text-xl text-white font-semibold mb-2">{{ course.description }}</h2>
          <p class="text-white text-base">{{ course.longDescription }}</p>

          <!-- Butoanele -->
          <div class="mt-4 flex space-x-2 lg:space-x-4">
            <a 
              [routerLink]="['/courses', course.url]" 
              class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full text-center block w-full">
              VIEW COURSE
            </a>
            <ng-container *ngIf="(userService.roles$ | async) as roles">
              <ng-container *ngIf="roles.admin">
                <button (click)="editCourse(course)" class="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-full text-center block w-full lg:w-auto">EDIT</button>
                <button (click)="deleteCourse(course)" class="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full text-center block w-full lg:w-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  DELETE
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


